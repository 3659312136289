var render = function () {
  var _vm$session$trials$_v, _vm$session$trials$_v2, _vm$session$trials$_v3, _vm$session$trials$_v8, _vm$session$trials$_v9, _vm$session$trials$_v10;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "step-5 d-flex"
  }, [_c('div', {
    staticClass: "left d-flex flex-column pa-2"
  }, [_c('ValidationObserver', {
    ref: "observer",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var invalid = _ref.invalid;
        return [_c('ValidationProvider', {
          attrs: {
            "rules": "required|alpha_dash_custom",
            "name": "Trial name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref2) {
              var errors = _ref2.errors;
              return [_c('v-text-field', {
                directives: [{
                  name: "show",
                  rawName: "v-show",
                  value: _vm.show_controls,
                  expression: "show_controls"
                }],
                staticClass: "flex-grow-0",
                attrs: {
                  "label": "Trial name",
                  "disabled": _vm.state !== 'ready',
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.trialName,
                  callback: function ($$v) {
                    _vm.trialName = $$v;
                  },
                  expression: "trialName"
                }
              })];
            }
          }], null, true)
        }), _c('v-btn', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.show_controls,
            expression: "show_controls"
          }],
          staticClass: "mb-4 w-100",
          attrs: {
            "disabled": _vm.busy || invalid
          },
          on: {
            "click": _vm.changeState
          }
        }, [_vm._v(" " + _vm._s(_vm.buttonCaption) + " ")]), _vm.state === 'recording' ? _c('p', [_vm._v(_vm._s(_vm.n_cameras_connected) + " devices are recording, do not refresh")]) : _vm._e(), _vm.state === 'processing' ? _c('p', [_vm._v(_vm._s(_vm.n_videos_uploaded) + " of " + _vm._s(_vm.n_cameras_connected) + " videos uploaded, do not refresh.")]) : _vm._e()];
      }
    }])
  }), _c('div', {
    staticClass: "trials flex-grow-1"
  }, _vm._l(_vm.filteredTrialsWithMenu, function (t, index) {
    return _c('div', {
      key: t.id,
      ref: t.id,
      refInFor: true,
      staticClass: "my-1 trial d-flex justify-content-between",
      class: {
        selected: _vm.isSelected(t)
      },
      attrs: {
        "item": t,
        "index": index
      }
    }, [_c('Status', {
      class: _vm.trialClasses(t),
      attrs: {
        "value": t
      },
      on: {
        "click": function ($event) {
          return _vm.loadTrial(t);
        }
      }
    }), _c('div', {}, [_c('v-menu', {
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref3) {
          var on = _ref3.on,
              attrs = _ref3.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": "",
              "dark": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu")])], 1)];
        }
      }], null, true),
      model: {
        value: t.isMenuOpen,
        callback: function ($$v) {
          _vm.$set(t, "isMenuOpen", $$v);
        },
        expression: "t.isMenuOpen"
      }
    }, [_c('v-list', [t.name !== 'neutral' ? _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function ($event) {
          return _vm.renameTrialDialog(t);
        }
      }
    }, [_c('v-list-item-title', [_vm._v("Rename")])], 1) : _vm._e(), !t.trashed && t.name !== 'neutral' ? _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function ($event) {
          return _vm.analysisDialog(t);
        }
      }
    }, [_c('v-list-item-title', [_vm._v("Analysis")])], 1) : _vm._e(), _c('v-list-item', {
      attrs: {
        "link": ""
      },
      on: {
        "click": function ($event) {
          return _vm.addTagTrialDialog(t);
        }
      }
    }, [_c('v-list-item-title', [_vm._v("Edit Tags")])], 1), _c('v-dialog', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.clickOutsideDialogTrialHideMenu,
        expression: "clickOutsideDialogTrialHideMenu"
      }],
      attrs: {
        "max-width": "500"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref4) {
          var on = _ref4.on;
          return [!t.trashed ? _c('v-list-item', _vm._g({
            attrs: {
              "link": ""
            }
          }, on), [_c('v-list-item-title', [_vm._v("Trash")])], 1) : _vm._e()];
        }
      }], null, true),
      model: {
        value: _vm.remove_dialog,
        callback: function ($$v) {
          _vm.remove_dialog = $$v;
        },
        expression: "remove_dialog"
      }
    }, [_c('v-card', [_c('v-card-text', {
      staticClass: "pt-4"
    }, [_c('v-row', {
      staticClass: "m-0"
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-icon', {
      attrs: {
        "x-large": "",
        "color": "red"
      }
    }, [_vm._v("mdi-close-circle")])], 1), _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('p', [_vm._v(" Do you want to trash trial " + _vm._s(t.name) + "? You will be able to restore it for 30 days. After that, this trial will be permanently removed. ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "blue darken-1",
        "text": ""
      },
      on: {
        "click": function ($event) {
          t.isMenuOpen = false;
          _vm.remove_dialog = false;
        }
      }
    }, [_vm._v(" No ")]), _c('v-btn', {
      attrs: {
        "color": "red darken-1",
        "text": ""
      },
      on: {
        "click": function ($event) {
          t.isMenuOpen = false;
          _vm.remove_dialog = false;

          _vm.trashTrial(t);
        }
      }
    }, [_vm._v(" Yes ")])], 1)], 1)], 1), _c('v-dialog', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.clickOutsideDialogTrialHideMenu,
        expression: "clickOutsideDialogTrialHideMenu"
      }],
      attrs: {
        "max-width": "500"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref5) {
          var on = _ref5.on;
          return [t.trashed ? _c('v-list-item', _vm._g({
            attrs: {
              "link": ""
            }
          }, on), [_c('v-list-item-title', [_vm._v("Restore")])], 1) : _vm._e()];
        }
      }], null, true),
      model: {
        value: _vm.restore_dialog,
        callback: function ($$v) {
          _vm.restore_dialog = $$v;
        },
        expression: "restore_dialog"
      }
    }, [_c('v-card', [_c('v-card-text', {
      staticClass: "pt-4"
    }, [_c('v-row', {
      staticClass: "m-0"
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-icon', {
      attrs: {
        "x-large": "",
        "color": "green"
      }
    }, [_vm._v("mdi-undo-variant")])], 1), _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('p', [_vm._v(" Do you want to restore trial " + _vm._s(t.name) + "? ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "blue darken-1",
        "text": ""
      },
      on: {
        "click": function ($event) {
          t.isMenuOpen = false;
          _vm.restore_dialog = false;
        }
      }
    }, [_vm._v(" No ")]), _c('v-btn', {
      attrs: {
        "color": "green darken-1",
        "text": ""
      },
      on: {
        "click": function ($event) {
          t.isMenuOpen = false;
          _vm.restore_dialog = false;

          _vm.restoreTrial(t);
        }
      }
    }, [_vm._v(" Yes ")])], 1)], 1)], 1), _c('v-dialog', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: _vm.clickOutsideDialogTrialHideMenu,
        expression: "clickOutsideDialogTrialHideMenu"
      }],
      attrs: {
        "max-width": "500"
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref6) {
          var on = _ref6.on;
          return [!t.trashed ? _c('v-list-item', _vm._g({
            attrs: {
              "link": ""
            }
          }, on), [_c('v-list-item-title', [_vm._v("Delete")])], 1) : _vm._e()];
        }
      }], null, true),
      model: {
        value: _vm.permanent_delete_dialog,
        callback: function ($$v) {
          _vm.permanent_delete_dialog = $$v;
        },
        expression: "permanent_delete_dialog"
      }
    }, [_c('v-card', [_c('v-card-text', {
      staticClass: "pt-4"
    }, [_c('v-row', {
      staticClass: "m-0"
    }, [_c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-icon', {
      attrs: {
        "x-large": "",
        "color": "red"
      }
    }, [_vm._v("mdi-close-circle")])], 1), _c('v-col', {
      attrs: {
        "cols": "10"
      }
    }, [_c('p', [_vm._v(" Do you want to permanently delete trial " + _vm._s(t.name) + "? This action cannot be undone. Use Trash to keep the ability to restore the trial. ")])])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "color": "blue darken-1",
        "text": ""
      },
      on: {
        "click": function ($event) {
          t.isMenuOpen = false;
          _vm.permanent_delete_dialog = false;
        }
      }
    }, [_vm._v(" No ")]), _c('v-btn', {
      attrs: {
        "color": "red darken-1",
        "text": ""
      },
      on: {
        "click": function ($event) {
          t.isMenuOpen = false;
          _vm.permanent_delete_dialog = false;

          _vm.permanentDeleteTrial(t);
        }
      }
    }, [_vm._v(" Yes ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
  }), 0), _c('v-btn', {
    staticClass: "mt-4 w-100",
    on: {
      "click": function ($event) {
        return _vm.toggleSessionMenuButtons();
      }
    }
  }, [_vm.showSessionMenuButtons ? _c('v-icon', [_vm._v("mdi-menu-down")]) : _c('v-icon', [_vm._v("mdi-menu-up")])], 1), _vm.showSessionMenuButtons ? _c('div', [_c('div', [_c('v-checkbox', {
    staticClass: "ml-2 m-2",
    attrs: {
      "label": "Show removed trials"
    },
    model: {
      value: _vm.show_trashed,
      callback: function ($$v) {
        _vm.show_trashed = $$v;
      },
      expression: "show_trashed"
    }
  })], 1), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show_controls,
      expression: "show_controls"
    }],
    staticClass: "w-100",
    attrs: {
      "small": "",
      "disabled": _vm.busy || _vm.state !== 'ready'
    },
    on: {
      "click": _vm.newSessionSameSetup
    }
  }, [_vm._v("New session, same setup ")]), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show_controls,
      expression: "show_controls"
    }],
    staticClass: "mt-4 w-100",
    attrs: {
      "small": "",
      "disabled": _vm.busy || _vm.state !== 'ready'
    },
    on: {
      "click": _vm.newSession
    }
  }, [_vm._v("New session ")]), _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref7) {
        var on = _ref7.on,
            attrs = _ref7.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.show_controls,
            expression: "show_controls"
          }],
          staticClass: "mt-4 w-100",
          attrs: {
            "small": ""
          }
        }, 'v-btn', attrs, false), on), [_vm._v("Share session publicly")])];
      }
    }], null, false, 124434547),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._v(" Share session publicly ")]), _c('v-card-text', [_c('v-checkbox', {
    attrs: {
      "id": "session-public",
      "name": "session-public",
      "label": "Make session public"
    },
    on: {
      "change": function ($event) {
        return _vm.setPublic($event);
      }
    },
    model: {
      value: _vm.session.public,
      callback: function ($$v) {
        _vm.$set(_vm.session, "public", $$v);
      },
      expression: "session.public"
    }
  }), _c('p', [_vm._v("Making your session public will make your analyses public too.")]), _c('v-container', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.session.public,
      expression: "session.public"
    }]
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v("Share on")]), _c('ShareNetwork', {
    staticClass: "mr-2",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "network": "facebook",
      "url": _vm.sessionUrl,
      "title": "OpenCap session"
    }
  }, [_c('v-btn', [_c('v-icon', {
    attrs: {
      "aria-hidden": "false"
    }
  }, [_vm._v("mdi-facebook")]), _vm._v("  Facebook")], 1)], 1), _c('ShareNetwork', {
    staticClass: "mr-2",
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "network": "twitter",
      "url": _vm.sessionUrl,
      "title": "OpenCap session"
    }
  }, [_c('v-btn', [_c('v-icon', {
    attrs: {
      "aria-hidden": "false"
    }
  }, [_vm._v("mdi-twitter")]), _vm._v("  Twitter")], 1)], 1), _c('ShareNetwork', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "network": "linkedin",
      "url": _vm.sessionUrl,
      "title": "OpenCap session"
    }
  }, [_c('v-btn', [_c('v-icon', {
    attrs: {
      "aria-hidden": "false"
    }
  }, [_vm._v("mdi-linkedin")]), _vm._v("  LinkedIn")], 1)], 1), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Alternatively, copy this link",
      "readonly": ""
    },
    model: {
      value: _vm.sessionUrl,
      callback: function ($$v) {
        _vm.sessionUrl = $$v;
      },
      expression: "sessionUrl"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1), _c('v-btn', {
    staticClass: "mt-4 w-100",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        _vm.showArchiveDialog = true;
      }
    }
  }, [_vm._v(" Download data ")]), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.showArchiveDialog,
      callback: function ($$v) {
        _vm.showArchiveDialog = $$v;
      },
      expression: "showArchiveDialog"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-row', {
    staticClass: "m-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "green"
    }
  }, [_vm._v("mdi-download")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm.isArchiveInProgress & !_vm.isArchiveDone ? _c('p', [_c('v-progress-circular', {
    staticClass: "mr-2",
    attrs: {
      "indeterminate": "",
      "color": "grey",
      "size": "14",
      "width": "2"
    }
  }), _vm._v(" Download in progress ")], 1) : _vm._e(), !(_vm.isArchiveInProgress || _vm.isArchiveDone) ? _c('p', [_vm._v(" Do you want to download all the data from session "), _c('code', [_vm._v(_vm._s(_vm.session.id))]), _vm._v("? (This can take several minutes). ")]) : _vm._e(), _vm.isArchiveDone ? _c('p', [_vm._v(" The archive has been successfully generated. Click on data.zip to download. ")]) : _vm._e()])], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.showArchiveDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _vm.isArchiveDone ? _c('v-btn', {
    attrs: {
      "href": _vm.archiveUrl,
      "download": "data.zip"
    }
  }, [_vm._v(" data.zip ")]) : _c('v-btn', {
    attrs: {
      "color": "green darken-1",
      "text": "",
      "disabled": _vm.isArchiveInProgress
    },
    on: {
      "click": function ($event) {
        return _vm.downloadSessionArchive(_vm.session.id);
      }
    }
  }, [_vm._v(" Download ")])], 1)], 1)], 1), _vm.isSyncDownloadAllowed ? _c('v-btn', {
    staticClass: "mt-4 w-100",
    attrs: {
      "small": "",
      "disabled": _vm.downloading
    },
    on: {
      "click": _vm.onDownloadData
    }
  }, [_vm.downloading ? _c('v-progress-circular', {
    staticClass: "mr-2",
    attrs: {
      "indeterminate": "",
      "color": "grey",
      "size": "14",
      "width": "2"
    }
  }) : _vm._e(), _vm._v(" Download data (old) ")], 1) : _vm._e(), _c('v-btn', {
    staticClass: "mt-4 w-100",
    attrs: {
      "small": ""
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'Dashboard',
          params: {
            id: _vm.session.id
          }
        });
      }
    }
  }, [_vm._v(" Dashboard kinematics ")]), _c('v-btn', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show_controls,
      expression: "show_controls"
    }],
    staticClass: "mt-4 w-100",
    attrs: {
      "small": "",
      "disabled": _vm.busy || _vm.state !== 'ready'
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          name: 'SelectSession'
        });
      }
    }
  }, [_vm._v(" Back to session list ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "viewer flex-grow-1"
  }, [_vm.trial ? _c('div', {
    staticClass: "d-flex flex-column h-100"
  }, [_c('div', {
    ref: "mocap",
    staticClass: "flex-grow-1",
    attrs: {
      "id": "mocap"
    }
  }), !_vm.videoControlsDisabled ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap",
      "align-items": "center"
    }
  }, [_c('v-text-field', {
    staticStyle: {
      "flex": "0.1",
      "margin-right": "5px"
    },
    attrs: {
      "label": "Time (s)",
      "type": "number",
      "step": 0.01,
      "value": _vm.time,
      "disabled": _vm.state !== 'ready',
      "dark": ""
    },
    on: {
      "input": _vm.onChangeTime
    }
  }), _c('v-slider', {
    staticClass: "mb-2",
    staticStyle: {
      "flex": "1"
    },
    attrs: {
      "value": _vm.frame,
      "min": 0,
      "max": _vm.frames.length - 1,
      "hide-details": ""
    },
    on: {
      "input": _vm.onNavigate
    }
  })], 1) : _vm._e()]) : _vm.trialLoading ? _c('div', {
    staticClass: "flex-grow-1 d-flex align-center justify-center"
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "color": "grey",
      "size": "30",
      "width": "4"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "right d-flex flex-column"
  }, [_c('div', {
    staticClass: "videos flex-grow-1 d-flex flex-column"
  }, _vm._l(_vm.videos, function (video, index) {
    return _c('video', {
      key: ("video-" + index),
      ref: ("video-" + index),
      refInFor: true,
      attrs: {
        "muted": "",
        "playsinline": "",
        "src": video.media,
        "crossorigin": "anonymous"
      },
      domProps: {
        "muted": true
      },
      on: {
        "ended": function ($event) {
          return _vm.onVideoEnded(index);
        }
      }
    });
  }), 0), _c('SpeedControl', {
    model: {
      value: _vm.playSpeed,
      callback: function ($$v) {
        _vm.playSpeed = $$v;
      },
      expression: "playSpeed"
    }
  }), _c('VideoNavigation', {
    staticClass: "mb-2",
    attrs: {
      "playing": _vm.playing,
      "value": _vm.frame,
      "maxFrame": _vm.frames.length - 1,
      "disabled": _vm.videoControlsDisabled
    },
    on: {
      "play": function ($event) {
        return _vm.togglePlay(true);
      },
      "pause": function ($event) {
        return _vm.togglePlay(false);
      },
      "input": _vm.onNavigate
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.trial_rename_dialog,
      callback: function ($$v) {
        _vm.trial_rename_dialog = $$v;
      },
      expression: "trial_rename_dialog"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-row', {
    staticClass: "m-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "orange"
    }
  }, [_vm._v("mdi-rename-box")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [((_vm$session$trials$_v = _vm.session.trials[_vm.trial_rename_index]) === null || _vm$session$trials$_v === void 0 ? void 0 : _vm$session$trials$_v.status) === 'processing' || ((_vm$session$trials$_v2 = _vm.session.trials[_vm.trial_rename_index]) === null || _vm$session$trials$_v2 === void 0 ? void 0 : _vm$session$trials$_v2.status) === 'uploading' ? _c('p', {
    staticClass: "text-orange"
  }, [_vm._v(" You can't rename a trial while it's being uploaded or processed. Please wait before attempting to rename the trial. ")]) : _c('p', [_vm._v(" Insert a new name for trial " + _vm._s((_vm$session$trials$_v3 = _vm.session.trials[_vm.trial_rename_index]) === null || _vm$session$trials$_v3 === void 0 ? void 0 : _vm$session$trials$_v3.name) + ": ")]), _c('ValidationObserver', {
    ref: "observer_tr",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref8) {
        var _vm$session$trials$_v6, _vm$session$trials$_v7;

        var invalid = _ref8.invalid;
        return [_c('ValidationProvider', {
          attrs: {
            "rules": "required|alpha_dash_custom",
            "name": "Trial name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (_ref9) {
              var _vm$session$trials$_v4, _vm$session$trials$_v5;

              var errors = _ref9.errors;
              return [_c('v-text-field', {
                staticClass: "flex-grow-0",
                attrs: {
                  "label": "Trial new name",
                  "disabled": _vm.state !== 'ready' || ((_vm$session$trials$_v4 = _vm.session.trials[_vm.trial_rename_index]) === null || _vm$session$trials$_v4 === void 0 ? void 0 : _vm$session$trials$_v4.status) === 'processing' || ((_vm$session$trials$_v5 = _vm.session.trials[_vm.trial_rename_index]) === null || _vm$session$trials$_v5 === void 0 ? void 0 : _vm$session$trials$_v5.status) === 'uploading',
                  "dark": "",
                  "error": errors.length > 0,
                  "error-messages": errors[0]
                },
                model: {
                  value: _vm.trialNewName,
                  callback: function ($$v) {
                    _vm.trialNewName = $$v;
                  },
                  expression: "trialNewName"
                }
              })];
            }
          }], null, true)
        }), _c('v-spacer'), _c('v-btn', {
          staticClass: "text-right",
          attrs: {
            "disabled": invalid || ((_vm$session$trials$_v6 = _vm.session.trials[_vm.trial_rename_index]) === null || _vm$session$trials$_v6 === void 0 ? void 0 : _vm$session$trials$_v6.status) === 'processing' || ((_vm$session$trials$_v7 = _vm.session.trials[_vm.trial_rename_index]) === null || _vm$session$trials$_v7 === void 0 ? void 0 : _vm$session$trials$_v7.status) === 'uploading'
          },
          on: {
            "click": function ($event) {
              _vm.trial_rename_dialog = false;

              _vm.renameTrial(_vm.session.trials[_vm.trial_rename_index], _vm.trial_rename_index, _vm.trialNewName);
            }
          }
        }, [_vm._v(" Rename Trial ")])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "max-width": "500"
    },
    model: {
      value: _vm.trial_modify_tags,
      callback: function ($$v) {
        _vm.trial_modify_tags = $$v;
      },
      expression: "trial_modify_tags"
    }
  }, [_c('v-card', [_c('v-card-text', {
    staticClass: "pt-4"
  }, [_c('v-row', {
    staticClass: "m-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": "",
      "color": "orange"
    }
  }, [_vm._v("mdi-rename-box")])], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [((_vm$session$trials$_v8 = _vm.session.trials[_vm.trial_modify_tags_index]) === null || _vm$session$trials$_v8 === void 0 ? void 0 : _vm$session$trials$_v8.status) === 'processing' || ((_vm$session$trials$_v9 = _vm.session.trials[_vm.trial_modify_tags_index]) === null || _vm$session$trials$_v9 === void 0 ? void 0 : _vm$session$trials$_v9.status) === 'uploading' ? _c('p', {
    staticClass: "text-orange"
  }, [_vm._v(" You can't modify trial tags while it's being uploaded or processed. Please wait before attempting to modify the tags. ")]) : _c('p', [_vm._v(" Insert the tags for trial " + _vm._s((_vm$session$trials$_v10 = _vm.session.trials[_vm.trial_modify_tags_index]) === null || _vm$session$trials$_v10 === void 0 ? void 0 : _vm$session$trials$_v10.name) + ": ")]), _c('ValidationObserver', {
    ref: "observer_tr_tag",
    staticClass: "d-flex flex-column",
    attrs: {
      "tag": "div"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Trial tags"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref10) {
        var errors = _ref10.errors;
        return [_c('v-select', {
          ref: "trialTagsSelect",
          attrs: {
            "clearable": "",
            "multiple": "",
            "item-title": "text",
            "item-value": "value",
            "label": "Trial Tags",
            "items": _vm.tagsOptions,
            "error": errors.length > 0,
            "error-messages": errors[0]
          },
          model: {
            value: _vm.trialNewTags,
            callback: function ($$v) {
              _vm.trialNewTags = $$v;
            },
            expression: "trialNewTags"
          }
        })];
      }
    }])
  }), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-right",
    attrs: {
      "disabled": _vm.trialNewTags.length === 0
    },
    on: {
      "click": function ($event) {
        _vm.trial_modify_tags = false;

        _vm.modifyTagsTrial(_vm.session.trials[_vm.trial_modify_tags_index], _vm.trial_modify_tags_index, _vm.trialNewTags);
      }
    }
  }, [_vm._v(" Apply Tags ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.clickOutsideDialogTrialHideMenu,
      expression: "clickOutsideDialogTrialHideMenu"
    }],
    attrs: {
      "max-width": "800"
    },
    model: {
      value: _vm.showAnalysisDialog,
      callback: function ($$v) {
        _vm.showAnalysisDialog = $$v;
      },
      expression: "showAnalysisDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Advanced Analysis")]), _vm.analysisFunctions.length > 0 ? _c('v-card-text', _vm._l(_vm.analysisFunctionsWithMenu, function (func, index) {
    return _c('v-row', {
      key: func.id,
      ref: func.id,
      refInFor: true,
      attrs: {
        "item": func,
        "index": index
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "3"
      }
    }, [_vm._v(" " + _vm._s(func.title) + " "), func.info.length > 0 ? _c('v-tooltip', {
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref11) {
          var on = _ref11.on;
          return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
        }
      }], null, true)
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(func.info.replace(/\n/g, '<br>'))
      }
    })]) : _vm._e()], 1), _c('v-col', {
      attrs: {
        "cols": "5"
      }
    }, [_vm._v(_vm._s(func.description))]), _c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [func.trials.includes(_vm.session.trials[_vm.trial_analysis_index].id) ? _c('v-btn', {
      attrs: {
        "small": "",
        "disabled": _vm.session.trials[_vm.trial_analysis_index].id in func.trials
      }
    }, [_c('span', [_c('v-progress-circular', {
      staticClass: "mr-2",
      attrs: {
        "indeterminate": "",
        "color": "grey",
        "size": "14",
        "width": "2"
      }
    }), _vm._v(" Calculating... ")], 1)]) : _vm._e(), !func.trials.includes(_vm.session.trials[_vm.trial_analysis_index].id) && !(_vm.session.trials[_vm.trial_analysis_index].id in func.states) ? _c('v-btn', {
      attrs: {
        "small": ""
      },
      on: {
        "click": function ($event) {
          var _vm$session$trials$_v11;

          return _vm.invokeAnalysisFunction(func.id, _vm.session.trials[_vm.trial_analysis_index].id, (_vm$session$trials$_v11 = _vm.session.trials[_vm.trial_analysis_index]) === null || _vm$session$trials$_v11 === void 0 ? void 0 : _vm$session$trials$_v11.name);
        }
      }
    }, [_vm._v(" Run ")]) : _vm._e(), _vm.session.trials[_vm.trial_analysis_index].id in func.states && !func.trials.includes(_vm.session.trials[_vm.trial_analysis_index].id) ? _c('v-btn', {
      attrs: {
        "small": ""
      }
    }, [_c('span', {
      style: func.states[_vm.session.trials[_vm.trial_analysis_index].id].state == 'failed' ? 'color:red' : 'color:green'
    }, [_vm._v(_vm._s(func.states[_vm.session.trials[_vm.trial_analysis_index].id].state))]), _c('v-menu', {
      attrs: {
        "offset-y": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref12) {
          var on = _ref12.on,
              attrs = _ref12.attrs;
          return [_c('v-btn', _vm._g(_vm._b({
            attrs: {
              "icon": "",
              "dark": ""
            }
          }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-menu")])], 1)];
        }
      }], null, true)
    }, [_c('v-list', [_c('v-list-item', {
      attrs: {
        "link": "",
        "disabled": _vm.trial_analysis_index in func.trials
      },
      on: {
        "click": function ($event) {
          var _vm$session$trials$_v12;

          return _vm.invokeAnalysisFunction(func.id, _vm.session.trials[_vm.trial_analysis_index].id, (_vm$session$trials$_v12 = _vm.session.trials[_vm.trial_analysis_index]) === null || _vm$session$trials$_v12 === void 0 ? void 0 : _vm$session$trials$_v12.name);
        }
      }
    }, [_vm._v(" Re-run ")]), func.states[_vm.session.trials[_vm.trial_analysis_index].id].dashboard_id != null && func.states[_vm.session.trials[_vm.trial_analysis_index].id].state == 'successfull' ? _c('v-list-item', {
      on: {
        "click": function ($event) {
          return _vm.goToAnalysisDashboard(func.states[_vm.session.trials[_vm.trial_analysis_index].id].dashboard_id, _vm.session.trials[_vm.trial_analysis_index].id);
        }
      }
    }, [_vm._v("Analysis Dashboard")]) : _vm._e(), _vm._l(func.states[_vm.session.trials[_vm.trial_analysis_index].id].menu, function (menu_item) {
      return _c('v-list-item', {
        key: menu_item.label,
        on: {
          "click": function ($event) {
            return _vm.requestDownloadMenuItem(_vm.session.trials[_vm.trial_analysis_index], menu_item);
          }
        }
      }, [_vm._v(_vm._s(menu_item.label))]);
    })], 2)], 1)], 1) : _vm._e()], 1)], 1);
  }), 1) : _c('v-card-text', [_c('p', [_vm._v("Sorry, there are no available functions.")])]), _c('v-card-actions', [_c('v-spacer'), _vm.analysisFunctions.length > 0 ? _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    }
  }, [_vm._v(" Reset results ")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "color": "red darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.session.trials[_vm.trial_analysis_index].isMenuOpen = false;
        _vm.showAnalysisDialog = false;
      }
    }
  }, [_vm._v(" Close ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }